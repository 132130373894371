import axios from "axios";
import { CognitoIdentityClient } from "@aws-sdk/client-cognito-identity";
import {
  fromCognitoIdentity,
  Logins,
} from "@aws-sdk/credential-provider-cognito-identity";
import { S3Client } from "@aws-sdk/client-s3";

import { REGION } from "./constants.ts";

export let authId = "";
const loginData: Logins = {};

const getS3Client = async () => {
  const isJudgeSplit = window.location ? window.location.href.split('/view_entry/judging/') : []
  let readEntryId: null | string = null
  if (isJudgeSplit.length === 2) {
    readEntryId = isJudgeSplit[1]
  }
  const {
    data: { result },
  } = await axios.get(
    `https://awards.griersontrust.org/uploader/api/get-cognito-credentials${readEntryId? `?entry_id=${readEntryId}` : ''}`
  );

  authId = result.IdentityId;
  loginData["cognito-identity.amazonaws.com"] = result.Token;

  const s3Client = new S3Client({
    region: REGION,
    credentials: fromCognitoIdentity({
      client: new CognitoIdentityClient({ region: REGION }),
      identityId: authId,
      logins: loginData,
    }),
  });

  // TODO: The token seems to change on every refresh. Make sure it doesn't!
  // s3Client.config.credentials().then((data) => console.log(data));

  return s3Client;
};

export default getS3Client;
