import { Dispatch, ReactChild, SetStateAction } from "react";

const Modal = ({ children, setModalOpen }) => {
  return (
    <>
      <div className="modalBg">
        <div className="modalWindow">
          {setModalOpen && (
            <div
              className="modalClose"
              onClick={() => setModalOpen(false)}
            >
              X
            </div>
          )}
          {children}
        </div>
      </div>
    </>
  );
};

export default Modal;
