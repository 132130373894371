import axios from "axios";
import {
  DeleteObjectCommand,
  DeleteObjectCommandInput,
  GetObjectCommand,
  GetObjectCommandInput,
  ListObjectsCommand,
  PutObjectCommand,
  PutObjectCommandInput,
  S3Client,
} from "@aws-sdk/client-s3";
import { getSignedUrl } from "@aws-sdk/s3-request-presigner";
import VideoPlayer from 'react-video-js-player';

import {
  useState,
  useEffect,
} from "react";
import getS3Client from "./aws/setup.ts";
import { BUCKET_NAME } from "./aws/constants.ts";
import './App.css';
import { formatBytes } from './utils/index.js';
import Modal from './Modal.js';

const API_BASE_DOMAIN = 'https://awards.griersontrust.org/';


export const awsGetUrl = async (s3Client, fileName, type) => {
  const fullName = `${type}/${fileName}`;

  const params = {
    Bucket: BUCKET_NAME,
    Key: fullName,
    // ResponseContentDisposition: "inline",
  };

  try {
    const command = new GetObjectCommand(params);
    const data = await getSignedUrl(s3Client, command, {
      // Link expires after 15 mins
      expiresIn: 900,
    });

    return data;
  } catch (err) {
    console.error(err);
  }
};

export const getPublicUrl = async (s3, fileName, type) => {
  const res = await awsGetUrl(s3, fileName, type);
  return res
}

function App() {
  const showStatus = false
  const [completeEntry, setCompleteEntry] = useState(false)
  const [completeEntryId, setCompleteEntryId] = useState('')
  const [playVideo, setPlayVideo] = useState('')
  const [entriesList, setEntriesList] = useState([])
  const [filesList, setFilesList] = useState([])
  const [publicUrls, setPublicUrls] = useState({})
  const [publicThumbnails, setPublicThumbnails] = useState({})
  const [error, setError] = useState(false);
  const [s3Client, setS3Client] = useState({});
  const [intervalStarted, setIntervalStarted] = useState(false)

  let entryId = null;
  const currentPage = window.location.href.split('/view_entry/show/');
  if (currentPage.length === 2) {
    entryId = currentPage[1]
  }
  const isCurrentPage = window.location.href.split('/view_entry/judging/');
  if (isCurrentPage.length === 2) {
    entryId = isCurrentPage[1]
  }
  // const entryId = new URLSearchParams(window.location.search).get("entry_id");

  const getUnfinishedEntriesList = async () => {
    try {
      const isJudgeSplit = window.location ? window.location.href.split('/view_entry/judging/') : []
      let readEntryId = null
      if (isJudgeSplit.length === 2) {
        readEntryId = isJudgeSplit[1]
      }
      const action = `get-user-unfinished-entries${readEntryId? `?entry_id=${readEntryId}` : ''}`;
      const response = await axios.get(`${API_BASE_DOMAIN}uploader/api/${action}`);
      if (response && response.data) {
        const { result, errMsg } = response.data;
        if (result && Array.isArray(result) && errMsg === null) {
          setEntriesList(result);
        }
      }
    } catch (error) {
      console.error(error);
    }
    setError(true);
  }

  const getEntriesFilesList = async (entryId) => {
    try {
      
      const action = `get-files-for-entry?entry_id=${entryId}`;
      const response = await axios.get(`${API_BASE_DOMAIN}uploader/api/${action}`);
      if (response && response.data) {
        const { result, errMsg } = response.data;
        if (result && Array.isArray(result) && errMsg === null) {
          setFilesList(result);
        }
      }
    } catch (error) {
      console.error(error);
    }
    setError(true);
  }

  useEffect(() => {
    if (entryId !== null) {
      getEntriesFilesList(entryId);
    } else {
      getUnfinishedEntriesList();
    }
    const getClient = async () => {
      const newClient = await getS3Client();
      setS3Client(newClient);
    };
    
    getClient();
  }, []);

  useEffect(() => {
    if (!intervalStarted && (entriesList.length > 0 || filesList.length > 0)) {
      window.setInterval(() => {
        if (entryId !== null) {
          getEntriesFilesList(entryId);
        } else {
          getUnfinishedEntriesList();
        }

      }, 6000);
      setIntervalStarted(true);
    }
    if (s3Client.hasOwnProperty("config")) {
      const filesToGet = []
      entriesList.forEach( entry => {
        const { files } = entry;
        if (files && Array.isArray(files)) {
          files.forEach( file => {
            const { fileId } = file;
            if ( !publicUrls.hasOwnProperty(fileId) ) {
              filesToGet.push(file)
            }
          })
        }
      })
      filesList.forEach( file => {
        const { fileId } = file;
        if ( !publicUrls.hasOwnProperty(fileId) ) {
          filesToGet.push(file)
        }
      })
      if (filesToGet.length > 0) {
        const getFileNames = async () => {
          const urls = {...publicUrls}
          const thumbnails = {...publicThumbnails}
          await Promise.all(filesToGet.map(async (file) => {
            const { fileId, status } = file
            const isTranscoded = status === 'transcoded'
            const isError = status === 'error'
            let type = isTranscoded ? 'transcoded' : 'uploads';
            const f = await getPublicUrl(s3Client, isTranscoded ? fileId.replace(/(\.mov)/i, '.mp4') : fileId, type);
            
            if (isTranscoded) {
              const thumbFile = fileId.replace(/(\.mp4)|(\.mov)/i, '-00001.jpg');
              const thumb = await getPublicUrl(s3Client, thumbFile, 'thumbnails');
              thumbnails[fileId] = thumb;
            }
            urls[fileId] = f;
          }));
          setPublicUrls({...urls});
          setPublicThumbnails({...thumbnails})
        }
        getFileNames();
      }
    }
  }, [entriesList, filesList, s3Client])
  
  const RenderFile = (file) => {
    const {type, originalFileName, size, fileId, status, canDownload} = file;
    const thumbnailType = type.replace(/(image\/)|(video\/)/gi, '');
    const publicLink = publicUrls.hasOwnProperty(fileId) ? publicUrls[fileId] : null
    const isVideo = publicLink && type.indexOf('video') !== -1
    const downloadLink = `/content/download?status=${status}&fileId=${encodeURIComponent(publicUrls[fileId])}&name=${encodeURIComponent(originalFileName)}&type=${type}`
    return (
      <div className="file-row">
        <div className="thumbnail">
          {isVideo ?
            <button 
              className="play-button"
              style={{backgroundImage: `url(${publicThumbnails[fileId]})`, backgroundSize: 'contain' }}
              onClick={() => {
                setPlayVideo(publicLink)
              }
            }>
              <b />
            </button>
            :
            <>
              {publicThumbnails.hasOwnProperty(fileId)? 
                <img src={publicThumbnails[fileId]} alt="" />
                :
                <span className={thumbnailType}></span>
              }
            </>
          }
        </div>
        <ul>
          <li><strong>{originalFileName}</strong></li>
          <li>Original file size: <span>{formatBytes(size)}</span></li>
          <li>Media type: <span>{type}</span></li>
          {showStatus && isVideo && <li>Transcode status: <span>{status || 'transcoding'}</span></li>}
          {publicUrls.hasOwnProperty(fileId) && canDownload && canDownload !== '0' && <li><a href={downloadLink} download>Download file</a></li>}
        </ul>
      </div>
    )
  }

  const RenderVideoPlayer = () => {
    return (
      <>
        {playVideo !== '' && 
          <div className="modal">
            <div className="container oversized-container">
              <section className="container">
                <a href="#close" className="close js-close-modal">Close</a>
                <button 
                  className="close"
                  onClick={() => {
                    setPlayVideo('')
                  }
                }>
                  Close
                </button>
                <div className="js-modal-content">
                  <VideoPlayer
                    controls={true}
                    src={playVideo}
                    width="720"
                    height="420"
                  />
                </div>
              </section>
            </div>
          </div>
        }
      </>
    )
  }

  if (filesList.length > 0) {
    return (
      <>
        {filesList.map((file) => {
            const {fileId} = file;
            return (
              <span key={fileId}>
                {RenderFile(file)}
              </span>
            )
          })
        }
        {RenderVideoPlayer()}
      </>
    )
  }

  return (
    <div>
      {entriesList.map(entry => {
        const {category, title, category_id, presenter_name, files, completed, id} = entry;
        if (completed !== "0") {
          return null
        }
        
        const useTitle = title || presenter_name

        return (
          <div key={id}>
            <table className="list file-list-table">
              <thead>
                <tr>
                  <th>Title</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    <h2><span><a href={`/view_entry/show/${id}`}>{useTitle}</a></span></h2>
                    <div className="file-list-content">
                      <ul className="entry-list">
                        <li>Category: {category}</li>
                      </ul>
                    </div>
                  </td>
                </tr>
                  <tr>
                    <td className="entry-last-row">
                      <div className="file-list-content">
                        <h3>Supporting files</h3>
                        {(!files || (files && files.length === 0)) && <p>At least one file is required for this entry.</p>}
                        {files && files.map((file) => {
                          const {fileId} = file;
                          return (
                            <span key={fileId}>
                              {RenderFile(file)}
                            </span>
                          )
                        })}
                      </div>
                    </td>
                  </tr>
              </tbody>
            </table>
            <a target="_blank" className="button icon-plus" href={`/uploader/index.html?entry_id=${id}`}>Upload supporting files</a>
            {files && files.length > 0 && 
              <>
                <span className="spacer">or</span>
                <a 
                  className="button complete" href={`/dashboard/complete_entry/${id}`}
                  onClick={(e) =>{
                    e.preventDefault()
                    setCompleteEntryId(id)
                    setCompleteEntry(true)
                  }}
                  >
                  Complete this entry
                </a>
              </>
            }
          </div>
        )
      })}
      {RenderVideoPlayer()}
      {completeEntry &&
        <Modal setModalOpen={setCompleteEntry}>
          <>
            <h3>Are you sure you want to complete this entry?</h3>
            <div className="modalButtonContainer">
              <button
                className="warning"
                onClick={() => {
                  setCompleteEntry(false)
                }}
              >
                No, go back
              </button>
              <button 
                onClick={() => {
                  setCompleteEntry(false)
                  window.location = `/dashboard/complete_entry/${completeEntryId}`
                  setCompleteEntryId('')
                }
              }>
                Yes
              </button>
            </div>
          </>
        </Modal>
      }
    </div>
  );
}

export default App;
